import React from "react"
import { Carousel } from "react-bootstrap"
import CustomButton from "../elements/CustomButton"
import { clientSuccessData } from "../../staticdata/caseStudySectionData"

const ClientSuccessCarousel = () => {
  return (
    <>
      <Carousel
        interval={3000}
        defaultActiveIndex={1}
        controls={false}
        indicators={clientSuccessData.length > 4 ? false : true}
        className="top-carousel"
      >
        {clientSuccessData.map((slide, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 image"
              src={slide.imageSrc}
              alt={slide.altText}
            />

            <Carousel.Caption>
              <h1 className="text-nowrap slide-heading">{slide.heading}</h1>
              <p>{slide.description}</p>
              <div>
                <CustomButton
                  reference={slide.redirection}
                  buttonTitle="Read More"
                  className="btn btn-top-carousel"
                  lineColor="line-white"
                />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  )
}

export default ClientSuccessCarousel
