import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ClientSuccessContainer from '../containers/ClientSuccessContainer'
const ClientPage = (props) => (
  <Layout 
  pageInfo={{ pageName: "Client Success" }} 
  styleClass="home-page" 
  location={props.location.pathname}>
    <SEO title="Client Success" keywords={[`gten`, `GTEN`, `technology`, `Client`, `Success`]} />
    <ClientSuccessContainer />
  </Layout>
)

export default ClientPage

